export class ConfigurationModel {
  activitiesCount: number = 3;
  widgetsCount: number = 1;
  maxWidth: string = "";
  activitiesWidth: string = "";
  widgetsWidth: string = "";
  totalColumns: number = 4;

  constructor(
    activityCt: number = 3,
    widgetsCt: number = 1,
    maxWidth: string = "1280px",
    totalColumns: number = 4
  ) {
    this.activitiesCount = activityCt;
    this.widgetsCount = widgetsCt;
    this.maxWidth = maxWidth;
    this.activitiesWidth = "100%";
    this.widgetsWidth = "100%";
    this.totalColumns = totalColumns;
  }

  get totalCount(): number {
    return this.activitiesCount + this.widgetsCount;
  }
}
