<template>
  <figure
    v-if="isPlaceholder"
    class="stock_logo placeholder"
    @click="goToStockDetail"
    @touchstart="goToStockDetail"
    :class="{ placeholderWithContent: placeholderContent }"
    :style="{
      width: size?.width,
      height: size?.height,
      flex: `0 0 ${size?.width}`,
    }"
  >
    <figcaption v-if="placeholderContent">
      {{ `${placeholderContent}+` }}
    </figcaption>
  </figure>
  <figure
    v-else
    class="stock_logo"
    :class="market"
    @touchstart="goToStockDetail"
    @click="goToStockDetail"
    :style="{
      width: size?.width,
      height: size?.height,
      flex: `0 0 ${size?.width}`,
    }"
  >
    <!-- <el-tooltip
      class="box-item"
      effect="dark"
      :content="symbol"
      placement="top-start"
    > -->
      <img
        ref="imgTag"
        v-if="$filters.validStockLogoUrl(imgUrl)"
        :src="imgUrl"
        @error="(event) => onImgLoadError(event)"
        :style="{
          width: size?.width,
          height: size?.height,
          flex: `0 0 ${size?.width}`,
        }"
      />
      <img
        ref="imgTag"
        @error="(event) => onImgLoadError(event)"
        v-else
        :src="getStockImage(symbol, market)"
        :style="{
          width: size?.width,
          height: size?.height,
          flex: `0 0 ${size?.width}`,
        }"
      />
    <!-- </el-tooltip> -->
    <div
      v-if="stockData && !hideHover"
      class="hoverEffectDv"
      ref="hoverWidget"
      :style="{
        top: rectArea.top + 'px',
        left: rectArea.left + 'px',
        position: 'fixed',
      }"
    >
      <StockHoverWidget
        :imgUrl="imgUrl"
        :symbol="symbol"
        :sparklineData="sparklineData"
        :market="market"
        :stockData="stockData"
      />
    </div>
  </figure>
</template>

<script lang="ts">
import { useStockSocket } from "@/composables/stock_socket.composable";
import { StockMarkets } from "@/helpers/models/stock/stocks.model";
import { StockSocketTransferDTO } from "@/helpers/models/stock/stock_socket_transfer.dto";
import { RouteEnum } from "@/router/routeNames";
import utilService from "@/services/util.service";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { popModal } from "jenesius-vue-modal";

export default defineComponent({
  props: {
    stockId: String,
    imgUrl: String,
    symbol: String,
    market: String,
    isPlaceholder: Boolean,
    size: Object,
    placeholderContent: Number,
    disableRouting: Boolean,
    hideHover: Boolean,
    // stockData: SocketStock,
    sparklineData: Object,
  },
  setup(props) {
    const getStockImage = utilService.getStockImage;
    const router = useRouter();

    const onImgLoadError = (er: any) => {
      try {
        er.srcElement.src = getStockImage(
          props.symbol!,
          props.market as StockMarkets
        );
      } catch (e) {}
    };
    const { addStockId } = useStockSocket();

    onMounted(() => {
      var stockId = props.stockId!;
      if (props.market == "crypto") {
        stockId = `${props.symbol}_CRYPTO`;
      }
      addStockId(new StockSocketTransferDTO(stockId));
    });

    const goToStockDetail = (ev: any) => {
      if (!props.disableRouting) {
        try {
          ev.stopPropagation();
        } catch (e) {}
        popModal();
        router.push({
          name: RouteEnum.stockDetail,
          params: { stockId: props.stockId },
        });
      }
    };

    const imgTag = ref<any>();

    return {
      imgTag,
      onImgLoadError,
      goToStockDetail,
      getStockImage,
    };
  },
});
</script>
<style lang="scss" scoped>
.hoverEffectDv {
  display: none;
  z-index: 99999;
  &:hover {
    z-index: 999;
    display: block;
  }
}

.stock_logo {
  overflow: visible !important;
  padding: 4px;
  border-radius: 10px;
  border: 4px solid transparent;
  cursor: pointer;
  background: var(--white-color);
  border-color: var(--color-icon-check);
  width: 25px;
  flex: 0 0 25px;
  height: 25px;
  &.large {
    width: 40px;
    flex: 0 0 40px;
    height: 40px;
  }

  // img {
  //   border-radius: 8px;
  // }
  &.placeholder {
    background: var(--card-background-color);
    border-color: var(--card-background-color);
  }
  &:hover {
    .hoverEffectDv {
      display: block;
      z-index: 99999;
    }
  }
  img {
    object-fit: contain;
    display: block;
  }
  &.ETFs {
    border-color: var(--color-light-purple11) !important;
  }
  &.futures {
    border-color: var(--cyan) !important;
  }
  &.crypto {
    border-color: var(--highlight-color) !important;
  }
  &.nft {
    border-color: var(--red) !important;
  }
  &.indices {
    border-color: var(--color-new-light-yollow2) !important;
  }
  &.options {
    border-color: var(--pink1) !important;
  }
}

.placeholderWithContent {
  text-align: center;
  background-color: var(--background-color) !important;
  border: 3px solid var(--color-grey4) !important;
  padding: 5.6px;
  color: var(--color-new-light-grey2);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

// @media screen and (max-width: 2305px) {
//   .stock_logo {
//     width: 25px;
//     flex: 0 0 25px;
//     height: 25px;
//   }
// }
</style>
