import {
  getAuth,
  signInAnonymously,
  signInWithPhoneNumber,
} from "firebase/auth";
import {
  UploadTask,
  getStorage,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import storageService from "./storage.service";
import utilService from "./util.service";
import { TYPE } from "vue-toastification";
import { getDownloadURL } from "firebase/storage";
import ChartHeader from "@/views/private/timeline/models/chartHeader.model";
import { doc, getDoc, getFirestore } from "firebase/firestore";

class FirebaseService {
  async uploadImage(file: any, fileName: string) {
    const storage = getStorage();
    const storageRef = ref(storage, "images/" + fileName);
    const metadata = {
      contentType: "image/jpeg",
    };
    const uploadResponse = await uploadBytes(storageRef, file, metadata);
    const fileUrl = await getDownloadURL(uploadResponse.ref);
    return fileUrl;
  }

  async getAuthToken() {
    const auth = getAuth();
    const data = await auth.currentUser?.getIdTokenResult();
    let user = storageService.getUser();
    if (data && user.uid) {
      storageService.setToken(data!.token);
    }
    return data?.token;
  }

  async logout() {
    const auth = getAuth();
    await auth.signOut();
  }

  async sendOTPCode(phoneNumber: string) {
    var gwin: any = window;
    const appVerifier = gwin.recaptchaVerifier;
    const auth = getAuth();
    var number = phoneNumber;
    if (!phoneNumber.startsWith("+")) {
      number = `+${phoneNumber}`;
    }
    await signInWithPhoneNumber(auth, number, appVerifier)
      .then((confirmationResult) => {
        gwin.confirmationResult = confirmationResult;
        utilService.showToast("Text message Code is being sent");
      })
      .catch((error) => {
        // utilService.showToast("Text message Code is not being sent", TYPE.ERROR);
      });
  }

  async anonymousLogin() {
    try {
      const auth = getAuth();
      var loginRes = await signInAnonymously(auth);
      return null;
    } catch (e) {
      return null;
    }
  }

  async uploadBase64Image(
    base64Data: string,
    sectionName: string,
    userId: string,
    type: string
  ) {
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `share_${userId}_${type}_${sectionName}.png`
    );
    try {
      const snapshot = await uploadString(storageRef, base64Data, "data_url", {
        contentType: "image/png",
      });
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      throw error;
    }
  }

  async uploadBase64ImageForTimeLine(
    base64Data: string,
    feedId: string,
    userId: string,
    type: string
  ) {
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `timeline_${userId}_${type}_${feedId}.png`
    );
    try {
      const snapshot = await uploadString(storageRef, base64Data, "data_url", {
        contentType: "image/png",
      });
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      throw error;
    }
  }

  async uploadMedia(media: any, path: string = "") {
    const storage = getStorage();
    const fileName: string = `${path}${new Date().getTime()}-${media.name}`;
    const storageRef = ref(storage, "images/" + fileName);
    const metadata = {
      contentType: media.type,
      contentDisposition: "attachment",
    };
    const uploadResponse = await uploadBytes(storageRef, media, metadata);
    const fileUrl = await getDownloadURL(uploadResponse.ref);
    return fileUrl;
  }

  async getChartHeader(stockId: string): Promise<ChartHeader | undefined> {
    var chartHeader: ChartHeader = new ChartHeader();
    const db = getFirestore();
    const docRef = doc(db, "stocks", stockId);
    var docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      var stock = docSnap.data();
      chartHeader = ChartHeader.copyFromStockFirebase(stock);
    }
    if (chartHeader && chartHeader.symbol) {
      return chartHeader;
    }
    return undefined;
  }

  getStockChart(
    stockId: string,
    duration: string,
    interval: string,
    chartType: string,
    dataTypes: any[] = ["Open"]
  ): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        dataTypes = dataTypes.filter((x) => x.isActive).map((y) => y.value);
        var docId = `${stockId}-${duration}-${interval}-${chartType}`;
        const db = getFirestore();
        const docRef = doc(db, "stockcharts", docId);
        var docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          var chartData = docSnap.data().chartData;
          if (chartType == "candlestick") {
            resolve(JSON.parse(chartData));
          } else {
            resolve(chartData.filter((x: any) => dataTypes.includes(x.name)));
          }
        }
      } catch (e) {}
      resolve([]);
    });
  }
}

export default new FirebaseService();
