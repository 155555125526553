<template>
  <div
    class="stat_item"
    v-if="!isEditableView || predefined || value != 0"
    @click.stop="handleClick(stat)"
    :style="{ borderColor: color }"
    :class="{
      shadeTwo: predefined || colorFade,
      compact: predefined,
      nonValue: value == 0,
      selectedOutline: stat.isSelected && hideTick,
      isLink: stat.key == 'followersCount' || stat.key == 'followingCount',
      disabled: disabled,
      'c-pointer': !showRemove,
    }"
  >
    <IconComponent
      icon="minus.svg"
      class="topRightIcon c-pointer"
      @click.stop="onRemove()"
      @touchstart="onRemove()"
      v-if="showRemove"
    />
    <IconComponent
      class="topRightIcon"
      icon="icon-tick-info-dv-two.svg"
      v-if="showSelected && stat.isSelected && !hideTick"
    />
    <div class="stat_item-text">
      <el-tooltip
        v-if="isOverflowing"
        class="box-item"
        effect="dark"
        :content="stat.label"
        placement="top-start"
      >
        <h6 ref="label">{{ stat.label }}</h6>
      </el-tooltip>
      <h6 v-else ref="label">{{ stat.label }}</h6>
      <p v-html="$filters.getStatsValue(stat.key, value ?? 0)"></p>
    </div>
    <div class="stat_item-icon" v-if="icon && showIcon" ref="statIconDv">
      <IconComponent :icon="icon + '.svg'" path="/stats-icon" />
    </div>
  </div>
</template>

<script lang="ts">
import storageService from "@/services/storage.service";
import utilService from "@/services/util.service";
import { defineComponent, onMounted, ref, watch, nextTick } from "vue";
import { useProfileScreenComposable } from "../../composables/profile.screen.composable";

export default defineComponent({
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    stat: Object,
    showSelected: Boolean,
    fromPopup: Boolean,
    showRemove: Boolean,
    userData: Object,
    colorFade: Boolean,
    isEditableView: {
      type: Boolean,
      default: false,
    },
    hideTick: {
      type: Boolean,
      default: false,
    },
    predefined: Boolean,
    disabled: Boolean,
  },
  setup(props, context) {
    const icon = ref<string>("icon-count");
    const color = ref<string>("#598aff");
    const statIconDv = ref<any>();
    const value = ref<any>();
    const user = ref<any>();

    const isOverflowing = ref(false);
    const label = ref<HTMLElement | null>(null);

    const checkOverflow = () => {
      nextTick(() => {
        if (label.value) {
          isOverflowing.value =
            label.value.scrollWidth > label.value.clientWidth;
        }
      });
    };

    watch(
      () => label,
      (newValue, oldValue) => {
        if (newValue && newValue !== oldValue) {
          checkOverflow();
        }
      }
    );

    const onRemove = () => context.emit("onRemove", true);

    const setStatsValue = () => {
      var valueStat = JSON.parse(JSON.stringify(props.stat));
      if (props.predefined) {
        value.value = valueStat.value;
        return;
      }
      if (props.userData) {
        if (props.userData!._id === user.value._id) {
          user.value = storageService.getUser();
        } else {
          user.value = props.userData!;
        }
      }
      if (valueStat.key == "followersCount") {
        value.value = user.value.followersCount ?? 0;
      }
      if (valueStat.key == "followingCount") {
        value.value = user.value.followingCount ?? 0;
      }
      if (valueStat.key == "friendCount") {
        value.value = user.value.friendCount ?? 0;
      }
      if (valueStat.key == "investmentsCount") {
        value.value = user.value.stats?.investmentsCount ?? 0;
      }
      if (valueStat.key == "investments") {
        value.value = user.value.stats?.investments ?? 0;
      }
      if (valueStat.key == "trades") {
        value.value = user.value.stats?.trades ?? 0;
      }
      if (valueStat.key == "tradesPrice") {
        value.value = user.value.stats?.tradesPrice ?? 0;
      }
      if (valueStat.key == "avgGainPrice") {
        value.value = user.value.stats?.avgGainPrice ?? 0;
      }
      if (valueStat.key == "avgGain") {
        value.value = user.value.stats?.avgGain ?? 0;
      }
      if (valueStat.key == "topGainPrice") {
        value.value = user.value.stats?.topGainPrice ?? 0;
      }
      if (valueStat.key == "topGain") {
        value.value = user.value.stats?.topGain ?? 0;
      }
      if (valueStat.key == "avgTradesPerMonth") {
        value.value = user.value.stats?.avgTradesPerMonth ?? 0;
      }
      if (valueStat.key == "avgTradesPerWeek") {
        value.value = user.value.stats?.avgTradesPerWeek ?? 0;
      }
      if (valueStat.key == "viewsCount") {
        value.value = user.value.viewsCount ?? 0;
      }
      if (valueStat.key == "outreach") {
        value.value = user.value.outreach ?? 0;
      }
      if (valueStat.key == "tradesThisMonth") {
        value.value = user.value.stats?.tradesThisMonth ?? 0;
      }
      if (valueStat.key == "tradesThisWeek") {
        value.value = user.value.stats?.tradesThisWeek ?? 0;
      }
      if (valueStat.key == "tradesThisYear") {
        value.value = user.value.stats?.tradesThisYear ?? 0;
      }
      if (valueStat.key == "todayTrades") {
        value.value = user.value.stats?.todayTrades ?? 0;
      }
      if (valueStat.key == "traderTypeCount") {
        value.value = user.value.stats?.traderTypeCount ?? 0;
      }
      if (valueStat.key == "bestTradeCount") {
        value.value = user.value.stats?.bestTradeCount ?? 0;
      }
      if (valueStat.key == "favoritePlatformCount") {
        value.value = user.value.stats?.favoritePlatformCount ?? 0;
      }
      if (valueStat.key == "recommendationCount") {
        value.value = user.value.stats?.recommendationCount ?? 0;
      }
      if (valueStat.key == "watchListCount") {
        value.value = user.value.stats?.watchListCount ?? 0;
      }
      if (valueStat.key == "listCount") {
        value.value = user.value.stats?.listCount ?? 0;
      }
      if (valueStat.key == "linksCount") {
        value.value = user.value.stats?.linksCount ?? 0;
      }
      if (valueStat.key == "imagesCount") {
        value.value = user.value.stats?.imagesCount ?? 0;
      }
      if (valueStat.key == "favoriteInfluencerCount") {
        value.value = user.value.stats?.favoriteInfluencerCount ?? 0;
      }
      if (valueStat.key == "favoriteInvestmentCount") {
        value.value = user.value.stats?.favoriteInvestmentCount ?? 0;
      }
      if (valueStat.key == "playbookCount") {
        value.value = user.value.stats?.playbookCount ?? 0;
      }
      if (valueStat.key == "profitAndLossCount") {
        value.value = user.value.stats?.profitAndLossCount ?? 0;
      }
      if (valueStat.key == "predictionCount") {
        value.value = user.value.stats?.predictionCount ?? 0;
      }
      if (valueStat.key == "socialLnksCount") {
        value.value = user.value.stats?.socialLnksCount ?? 0;
      }
      return;
    };

    onMounted(() => {
      user.value = storageService.getUser();
      mapValueForStat(props.stat);
      checkOverflow();
      setStatsValue();
    });

    watch(props, () => {
      user.value = storageService.getUser();
      mapValueForStat(props.stat);
      setStatsValue();
    });

    const mapValueForStat = (stat: any) => {
      if (!stat.key) {
        stat.key = utilService.getStatKey(stat.label);
      }
      stat.label = utilService.getStatLabel(stat);
      if (stat.key == "followersCount") {
        color.value = "#ff851b";
        icon.value = "icon-followers";
      }
      if (stat.key == "followingCount") {
        color.value = "#21c1fd";
        icon.value = "icon-following";
      }
      if (stat.key == "friendCount") {
        color.value = "#cbff18";
        icon.value = "icon-friends";
      }
      if (stat.key == "investmentsCount") {
        color.value = "#598aff";
        icon.value = "icon-investment";
      }
      if (stat.key == "investments") {
        color.value = "#ff6ea3";
        icon.value = "icon-price";
      }
      if (stat.key == "trades") {
        color.value = "#fcf6cb";
        icon.value = "icon-trade";
      }
      if (stat.key == "tradesPrice") {
        color.value = "#6ffcd0";
        icon.value = "icon-trade-price";
      }
      if (stat.key == "avgGainPrice") {
        color.value = "#39d3d3";
        icon.value = "icon-price";
      }
      if (stat.key == "avgGain") {
        color.value = "#34E36F";
        icon.value = "icon-count";
      }
      if (stat.key == "topGainPrice") {
        color.value = "#c7ace9";
        icon.value = "icon-price";
      }
      if (stat.key == "topGain") {
        color.value = "#f2aa1f";
        icon.value = "icon-count";
      }
      if (stat.key == "avgTradesPerMonth") {
        icon.value = "icon-calendar";
        color.value = "#34e36f";
      }
      if (stat.key == "avgTradesPerWeek") {
        color.value = "#934bff";
        icon.value = "icon-calendar";
      }
      if (stat.key == "viewsCount") {
        color.value = "#00bcd4";
        icon.value = "icon-view";
      }
      if (stat.key == "outreach") {
        color.value = "#009e3a";
        icon.value = "icon-outreach";
      }
      setColor();
    };

    const setColor = () => {
      if (statIconDv.value) {
        statIconDv.value.children[0].style.stroke = color.value;
        statIconDv.value.style.background = `rgba(${utilService.hexToRgb(
          color.value
        )}, 0.2)`;
        return;
      }
      setTimeout(() => {
        setColor();
      }, 10);
    };

    const { handleStatsClick } = useProfileScreenComposable();

    const handleClick = (data: any) => {
      if (props.fromPopup) {
      } else {
        handleStatsClick(data, props!.userData!._id);
      }
    };

    return {
      statIconDv,
      handleClick,
      value,
      icon,
      onRemove,
      color,
      isOverflowing,
      label,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "./topstats";
</style>
