<template>
  <div class="app-modal modal modal-fullscreen">
    <div class="app-modal--head flexRow">
      <IconComponent
        @click="dismiss()"
        class="back-btn back-btn-container"
        icon="icon-chevron-left.svg"
      />
      <h6>{{ existingPlaybook ? "Edit" : "Add New" }} Playbook</h6>
      <div class="back-btn-container"></div>
    </div>
    <div class="app-modal--body">
      <el-scrollbar class="verticalScroller newScrollbar native" :native="true">
        <div
          class="modal_list_container watchlist watchlistForm playbookfromDv"
        >
          <!-- <div class="editmodePlaybookDv">
            <PlayBookItem
              :fromPopup="true"
              :trade="previewTrade"
              :editMode="true"
              :playbookTitle="playbookData.title"
              :playbookDate="playbookData.date"
              :disableRouting="true"
              class="c-pointer"
              :showRemove="false"
            />
     
          </div> -->
          <!-- <div
            class="watchlist-row gridchangeWatchlistForm"
            v-if="existingPlaybook"
          >
            <div class="col firstRow">
              <h3>Playbook Title</h3>
              <div class="searchinfoDv">
                <el-input
                  placeholder="ex Top Gainer"
                  maxlength="25"
                  max="25"
                  @keypress="updatePreviewCard()"
                  @keyup="updatePreviewCard()"
                  class="WinningTradeinputDv"
                  type="text"
                  v-model="playbookData.title"
                />
              </div>
            </div>
          </div>
          <div
            class="watchlist-row gridchangeWatchlistForm"
            v-if="existingPlaybook"
          >
            <div class="col firstRow">
              <h3>Playbook Title</h3>
              <div class="searchinfoDv">
                <el-input
                  placeholder="ex Top Gainer"
                  maxlength="25"
                  max="25"
                  class="WinningTradeinputDv"
                  type="text"
                  v-model="playbookData.title"
                />
              </div>
            </div>
          </div>
          <div
            class="watchlist-row gridchangeWatchlistForm"
            v-if="!existingPlaybook"
          >
            <div class="col firstRow">
              <h3>Playbook Title</h3>
              <div class="searchinfoDv">
                <el-input
                  placeholder="ex Top Gainer"
                  maxlength="25"
                  max="25"
                  @keypress="updatePreviewCard()"
                  @keyup="updatePreviewCard()"
                  class="WinningTradeinputDv"
                  type="text"
                  v-model="playbookData.title"
                />
              </div>
            </div>
            <div class="col">
              <h3>Date</h3>
              <div class="searchinfoDv">
                <el-date-picker
                  class="ProfitAndLossinputDv newInputSecondDv"
                  :disabled-date="disabledDate"
                  @change="updatePreviewCard()"
                  v-model="playbookData.timestamp"
                  type="date"
                  placeholder="Select Date"
                />
              </div>
            </div>
          </div> -->
          <div class="gridinfoexistingplaybookDv">
            <div class="editmodePlaybookDv">
              <PlayBookItem
                :fromPopup="true"
                :trade="previewTrade"
                :editMode="true"
                :playbookTitle="playbookData.title"
                :playbookDate="playbookData.date"
                :disableRouting="true"
                class="c-pointer"
                :showRemove="false"
              />
              <IconComponent
                class="iconDv"
                @click="deletePlaybook()"
                v-if="existingPlaybook"
                @touchstart="deletePlaybook()"
                id="minusWining"
                icon="minus.svg"
              />
            </div>
            <div class="watchlist-row playbookRow">
              <StockSelectComponent
                :fromWatchlist="true"
                :fromPlaybook="true"
                :key="selectedStock"
                :stock="selectedStock"
                @onStockUpdate="($event) => onStockChange($event)"
              />
              <div class="PlaybookFlowCardDvChanging">
                <!-- <div class="switchInfoDv">
                  <h3>Stock</h3>
                  <el-switch
                    class="leftinfoDvCheckBoxPlaybookInfoDv"
                    @change="ChangeOption()"
                    v-model="value1"
                  />
                  <h3>Option</h3>
                </div> -->
                <div class="LongShortDv">
                  <h2
                    class="long"
                    :class="{
                      activeNowLong: playbookForm.traderType === 'long',
                    }"
                    @click="toggleChange('long')"
                  >
                    Long
                  </h2>
                  <h2
                    @click="toggleChange('short')"
                    class="short"
                    :class="{
                      activeNowShort: playbookForm.traderType === 'short',
                    }"
                  >
                    Short
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div
            class="watchlist-row inline-fields quad-col"
            :class="{ disabledSelectedStock: !selectedStock }"
          >
            <div class="col firstRow">
              <h3>Entry</h3>
              <div class="searchinfoDv newTextFeildDv">
                <IconComponent
                  class="iconinfoDv"
                  icon="icon-playbook-stockactualprice.svg"
                />
                <div class="newInputInfoDv">
                  <IconComponent
                    class="iconinfoDv"
                    icon="icon-playbook-add.svg"
                    @click="handlePrice('entry', 'plus')"
                  />
                  <div>
                    <el-input
                      class="newInputDv"
                      placeholder="Enter Price"
                      :disabled="!selectedStock"
                      max="9999999"
                      type="number"
                      @keypress="$filters.validateNumberInput($event, 9999999)"
                      v-model="playbookForm.entry"
                    />
                  </div>
                  <IconComponent
                    class="iconinfoDv"
                    icon="icon-playbook-minus.svg"
                    @click="handlePrice('entry', 'minus')"
                  />
                </div>
              </div>
            </div>
            <div class="col">
              <h3>Stop Loss</h3>
              <div class="searchinfoDv newTextFeildDv">
                <IconComponent
                  class="iconinfoDv"
                  icon="icon-playbook-stockstopprice.svg"
                />
                <div class="newInputInfoDv">
                  <IconComponent
                    class="iconinfoDv"
                    @click="handlePrice('stoploss', 'plus')"
                    icon="icon-playbook-add.svg"
                  />
                  <div>
                    <el-input
                      class="newInputDv"
                      placeholder="Enter Price"
                      :disabled="!selectedStock"
                      max="9999999"
                      type="number"
                      @keypress="$filters.validateNumberInput($event, 9999999)"
                      v-model="playbookForm.stopLoss"
                    />
                  </div>
                  <IconComponent
                    class="iconinfoDv"
                    @click="handlePrice('stoploss', 'minus')"
                    icon="icon-playbook-minus.svg"
                  />
                </div>
              </div>
            </div>
            <div class="col firstRow">
              <h3>Target 1</h3>
              <div class="searchinfoDv newTextFeildDv">
                <IconComponent
                  class="iconinfoDv"
                  icon="icon-playbook-stocktargetoneprice.svg"
                />
                <div class="newInputInfoDv">
                  <IconComponent
                    class="iconinfoDv"
                    @click="handlePrice('targetOne', 'plus')"
                    icon="icon-playbook-add.svg"
                  />
                  <div>
                    <el-input
                      class="newInputDv"
                      placeholder="Enter Price"
                      :disabled="!selectedStock"
                      max="9999999"
                      @keyup="updatePreviewCard()"
                      type="number"
                      @keypress="$filters.validateNumberInput($event, 9999999)"
                      v-model="playbookForm.targetOne"
                    />
                  </div>
                  <IconComponent
                    class="iconinfoDv"
                    @click="handlePrice('targetOne', 'minus')"
                    icon="icon-playbook-minus.svg"
                  />
                </div>
              </div>
            </div>
            <div class="col">
              <h3>Target 2</h3>
              <div class="searchinfoDv newTextFeildDv">
                <IconComponent
                  class="iconinfoDv"
                  icon="icon-playbook-stocktargettwoprice.svg"
                />
                <div class="newInputInfoDv">
                  <IconComponent
                    class="iconinfoDv"
                    @click="handlePrice('targetTwo', 'plus')"
                    icon="icon-playbook-add.svg"
                  />
                  <div>
                    <el-input
                      class="newInputDv"
                      :disabled="!selectedStock"
                      placeholder="Enter Price"
                      max="9999999"
                      type="number"
                      @keypress="$filters.validateNumberInput($event, 9999999)"
                      v-model="playbookForm.targetTwo"
                    />
                  </div>
                  <IconComponent
                    class="iconinfoDv"
                    @click="handlePrice('targetTwo', 'minus')"
                    icon="icon-playbook-minus.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="dual-space-between"
            :class="{ disabledSelectedStock: !selectedStock }"
          >
            <div class="CustomCheckboxWrapper deletePlaybook">
              <div class="checkbox_wrapper widget">
                <input
                  type="checkbox"
                  class="custom-checkbox"
                  :id="`info${playbookData.strategy}`"
                  :value="playbookData.strategy"
                  :checked="playbookData.strategy"
                  @change="changeStatus()"
                />
              </div>
              <div class="checkboxLableDv">I am playing this strategy</div>
            </div>
            <div class="watchlist-row watchlist-row-buttons">
              <button
                class="btn btn--primary secondary"
                :class="{ disabled: !selectedStock }"
                @click="showNotesModal()"
              >
                <IconComponent
                  class="iconinfoDv"
                  icon="icon-playbook-stocknotes.svg"
                />Notes
              </button>
              <button
                class="btn btn--primary"
                :class="{ disabled: !selectedStock }"
                @click.stop="addStock()"
                v-if="editableIndex == -1"
                @touchstart="addStock()"
              >
                Add Entry
              </button>
              <button
                class="btn btn--primary"
                :class="{ disabled: !selectedStock }"
                @click.stop="updateStock()"
                v-else
                @touchstart="updateStock()"
              >
                Update Entry
              </button>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div class="app-modal--footer">
      <SelectionPreview :count="stockListing.length" />
      <p class="error_txt" v-if="stockListing.length == 0">No Data Found</p>
      <template v-else>
        <el-scrollbar
          class="verticalScroller newScrollbar native playbookFooterScroll"
          :native="true"
        >
          <div class="playbookViewCardGridDv">
            <draggable
              :force-fallback="true"
              @start="handleDragEvent('start')"
              @end="handleDragEvent('stop')"
              v-model="stockListing"
              item-key="index"
              class="watchlist-listing-container lastinfoDvWatchlistContainer"
            >
              <template #item="{ element, index }">
                <ViewPlayBookCardItem
                  @onRemove="removeStock(index)"
                  class="c-pointer"
                  :showRemove="true"
                  @click.stop="onEventUpdate(element, index)"
                  :class="{
                    shortActive: element.traderType == 'short',
                    longActive: element.traderType == 'long',
                  }"
                  :stock="element"
                />
              </template>
            </draggable>
          </div> </el-scrollbar
      ></template>
      <div class="app-modal--footer-buttons">
        <!-- <button class="btn btn--primary" v-if="isDeleteLoading">
          <div class="loader"></div>
        </button> -->
        <!-- <button
          class="btn btn--primary danger"
          v-else-if="existingPlaybook"
          @click="deletePlaybook()"
        >
          Delete
        </button> -->
        <button class="btn btn--outline" @click="dismiss()">Cancel</button>
        <button class="btn btn--primary" v-if="isLoading">
          <div class="loader"></div>
        </button>
        <button
          class="btn btn--primary"
          @click="updatePlaybook()"
          v-else-if="existingPlaybook"
        >
          Update
        </button>
        <button class="btn btn--primary" @click="addPlaybook()" v-else>
          Add
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import StockSelectComponent from "../../stock-select-component/StockSelectComponent.vue";
import ViewPlayBookCardItem from "../ViewPlayBookCardItem.vue";
import PlayBookItem from "../PlayBookItem.vue";
import SelectionPreview from "../SelectionPreview.vue";
import {
  PlayBooksModel,
  PlayBooks,
  PreviewPlayBooks,
} from "@/helpers/models/playbooks.model";
import { SocketStock } from "@/helpers/models/stock/socket_stock.model";
import utilService from "@/services/util.service";
import { popModal, pushModal } from "jenesius-vue-modal";
import { TYPE } from "vue-toastification";
import httpService from "@/services/http.service";
import AddNotesComponent from "./AddNotesComponent.vue";

export default defineComponent({
  props: {
    existingPlaybook: PlayBooks,
  },
  components: {
    ViewPlayBookCardItem,
    SelectionPreview,
    StockSelectComponent,
    PlayBookItem,
  },
  emits: ["onRemove", "formCompleted"],
  setup(props, context) {
    const value1 = ref(true);
    const isLoading = ref<boolean>(false);
    const selectedStock = ref<any>();
    const isDeleteLoading = ref<boolean>(false);
    const stockListing = ref<PlayBooksModel[]>([]);
    const previewTrade = ref<PreviewPlayBooks>();
    const playbookData = ref<any>({});
    const editableIndex = ref<number>(-1);
    const editMode = ref<boolean>(false);

    const playbookForm = ref<any>({
      targetOne: 0,
      targetTwo: 0,
      entry: 0,
      stopLoss: 0,
      traderType: "long",
    });

    onMounted(() => {
      playbookData.value.strategy = false;
      if (props.existingPlaybook && props.existingPlaybook._id) {
        var currentData = JSON.parse(JSON.stringify(props.existingPlaybook));
        playbookData.value.timestamp = currentData.timestamp;
        stockListing.value = currentData.stocks!;
        playbookData.value.title = currentData.title;
        if (currentData.strategy) {
          playbookData.value.strategy = currentData.strategy;
          changeStatusAgain(playbookData.value.strategy);
        } else {
          playbookData.value.strategy = false;
          changeStatusAgain(playbookData.value.strategy);
        }
      } else {
      }
      updatePreviewCard();
    });

    const updatePreviewCard = () => {
      previewTrade.value = new PreviewPlayBooks();
      let title = playbookData.value.title;
      let timestamp = playbookData.value.timestamp;
      if (!playbookData.value.title) {
        title = "-";
      }
      if (!playbookData.value.timestamp) {
        timestamp = "-";
      }
      if (!previewTrade.value.title || previewTrade.value.title === "-") {
        previewTrade.value.title = title;
      }
      if (
        !previewTrade.value.timestamp ||
        previewTrade.value.timestamp === "-"
      ) {
        previewTrade.value.timestamp = timestamp;
      }
      if (!previewTrade.value.strategy) {
        previewTrade.value.strategy = playbookData.value.strategy;
      }
      if (previewTrade.value.stocks && previewTrade.value.stocks.length === 0) {
        previewTrade.value.stocks = stockListing.value;
      }
    };

    const onStockChange = (stock: any) => {
      selectedStock.value = stock;
      toggleChange(playbookForm.value.traderType ?? "long");
      if (selectedStock.value == undefined) {
        playbookForm.value.targetOne = 0;
        playbookForm.value.targetTwo = 0;
        playbookForm.value.entry = 0;
        playbookForm.value.stopLoss = 0;
      }
    };

    const addStock = () => {
      if (selectedStock.value) {
        if (playbookForm.value.traderType) {
          if (playbookForm.value.traderType === "long") {
            if (playbookForm.value!.stopLoss < playbookForm.value!.entry) {
              if (playbookForm.value!.targetOne > playbookForm.value!.entry) {
                if (
                  playbookForm.value!.targetTwo > playbookForm.value!.targetOne
                ) {
                  selectedStock.value.targetOne = playbookForm.value.targetOne;
                  selectedStock.value.targetTwo = playbookForm.value.targetTwo;
                  selectedStock.value.entry = playbookForm.value.entry;
                  selectedStock.value.stopLoss = playbookForm.value.stopLoss;
                  selectedStock.value.traderType =
                    playbookForm.value.traderType;
                  stockListing.value.splice(0, 0, selectedStock.value!);
                  onStockChange(undefined);
                  playbookForm.value.targetOne = 0;
                  playbookForm.value.targetTwo = 0;
                  playbookForm.value.entry = 0;
                  playbookForm.value.stopLoss = 0;
                  playbookForm.value.traderType = "long";
                  playbookData.value.title = previewTrade.value?.title;
                  playbookData.value.timestamp = previewTrade.value?.timestamp;
                  updatePreviewCard();
                  return true;
                } else {
                  return utilService.showToast(
                    "Target 2 Must Be Greater Than Target 1",
                    TYPE.ERROR
                  );
                }
              } else {
                return utilService.showToast(
                  "Target 1 Must Be Greater Than Entry Price",
                  TYPE.ERROR
                );
              }
            } else {
              return utilService.showToast(
                "Stoploss Must Be Less Than Entry Price",
                TYPE.ERROR
              );
            }
          }
          if (playbookForm.value.traderType === "short") {
            if (playbookForm.value!.stopLoss > playbookForm.value!.entry) {
              if (playbookForm.value!.targetOne < playbookForm.value!.entry) {
                if (
                  playbookForm.value!.targetTwo < playbookForm.value!.targetOne
                ) {
                  selectedStock.value.targetOne = playbookForm.value.targetOne;
                  selectedStock.value.targetTwo = playbookForm.value.targetTwo;
                  selectedStock.value.entry = playbookForm.value.entry;
                  selectedStock.value.stopLoss = playbookForm.value.stopLoss;
                  selectedStock.value.traderType =
                    playbookForm.value.traderType;
                  stockListing.value.splice(0, 0, selectedStock.value!);
                  onStockChange(undefined);
                  playbookForm.value.targetOne = 0;
                  playbookForm.value.targetTwo = 0;
                  playbookForm.value.entry = 0;
                  playbookForm.value.stopLoss = 0;
                  playbookForm.value.traderType = "long";
                  playbookData.value.title = previewTrade.value?.title;
                  playbookData.value.timestamp = previewTrade.value?.timestamp;
                  updatePreviewCard();
                  return true;
                } else {
                  return utilService.showToast(
                    "Target 2 Must Be Less Than Target 1",
                    TYPE.ERROR
                  );
                }
              } else {
                return utilService.showToast(
                  "Target 1 Must Be Less Than Entry Price",
                  TYPE.ERROR
                );
              }
            } else {
              return utilService.showToast(
                "Stoploss Must Be Greater Than Entry Price",
                TYPE.ERROR
              );
            }
          }
        }
      }
    };

    const updateStock = () => {
      if (selectedStock.value) {
        if (playbookForm.value.traderType) {
          if (playbookForm.value.traderType === "long") {
            if (playbookForm.value!.stopLoss < playbookForm.value!.entry) {
              if (playbookForm.value!.targetOne > playbookForm.value!.entry) {
                if (
                  playbookForm.value!.targetTwo > playbookForm.value!.targetOne
                ) {
                  selectedStock.value.targetOne = playbookForm.value.targetOne;
                  selectedStock.value.targetTwo = playbookForm.value.targetTwo;
                  selectedStock.value.entry = playbookForm.value.entry;
                  selectedStock.value.stopLoss = playbookForm.value.stopLoss;
                  selectedStock.value.traderType =
                    playbookForm.value.traderType;
                  stockListing.value[editableIndex.value] = selectedStock.value;
                  editMode.value = false;
                  editableIndex.value = -1;
                  onStockChange(undefined);
                  playbookForm.value.targetOne = 0;
                  playbookForm.value.targetTwo = 0;
                  playbookForm.value.entry = 0;
                  playbookForm.value.stopLoss = 0;
                  playbookForm.value.traderType = "long";
                  playbookData.value.title = previewTrade.value?.title;
                  playbookData.value.timestamp = previewTrade.value?.timestamp;
                  updatePreviewCard();
                  return true;
                } else {
                  return utilService.showToast(
                    "Target 2 Must Be Greater Than Target 1",
                    TYPE.ERROR
                  );
                }
              } else {
                return utilService.showToast(
                  "Target 1 Must Be Greater Than Entry Price",
                  TYPE.ERROR
                );
              }
            } else {
              return utilService.showToast(
                "Stoploss Must Be Less Than Entry Price",
                TYPE.ERROR
              );
            }
          }
          if (playbookForm.value.traderType === "short") {
            if (playbookForm.value!.stopLoss > playbookForm.value!.entry) {
              if (playbookForm.value!.targetOne < playbookForm.value!.entry) {
                if (
                  playbookForm.value!.targetTwo < playbookForm.value!.targetOne
                ) {
                  selectedStock.value.targetOne = playbookForm.value.targetOne;
                  selectedStock.value.targetTwo = playbookForm.value.targetTwo;
                  selectedStock.value.entry = playbookForm.value.entry;
                  selectedStock.value.stopLoss = playbookForm.value.stopLoss;
                  selectedStock.value.traderType =
                    playbookForm.value.traderType;
                  stockListing.value[editableIndex.value] = selectedStock.value;
                  editMode.value = false;
                  editableIndex.value = -1;
                  onStockChange(undefined);
                  playbookForm.value.targetOne = 0;
                  playbookForm.value.targetTwo = 0;
                  playbookForm.value.entry = 0;
                  playbookForm.value.stopLoss = 0;
                  playbookForm.value.traderType = "long";
                  playbookData.value.title = previewTrade.value?.title;
                  playbookData.value.timestamp = previewTrade.value?.timestamp;
                  updatePreviewCard();
                  return true;
                } else {
                  return utilService.showToast(
                    "Target 2 Must Be Less Than Target 1",
                    TYPE.ERROR
                  );
                }
              } else {
                return utilService.showToast(
                  "Target 1 Must Be Less Than Entry Price",
                  TYPE.ERROR
                );
              }
            } else {
              return utilService.showToast(
                "Stoploss Must Be Greater Than Entry Price",
                TYPE.ERROR
              );
            }
          }
        }
      }
    };

    const dismiss = () => popModal();

    const isFormValid = computed<string>(() => {
      if (previewTrade.value! && !previewTrade.value.title) {
        return "Please! Enter Title To Continue";
      }
      if (previewTrade.value! && !previewTrade.value!.timestamp) {
        return "Please! Select Date";
      }
      if (previewTrade.value! && previewTrade.value!.title === "-") {
        return "Please! Enter Title To Continue";
      }
      if (previewTrade.value! && previewTrade.value!.timestamp === "-") {
        return "Please! Select Date";
      }
      if (stockListing.value && !stockListing.value.length) {
        return "Please! Select Stocks";
      }
      return "";
    });

    const handleDragEvent = utilService.onDraggableDragEvent;

    const removeStock = (ind: number) => {
      stockListing.value.splice(ind, 1);
      updatePreviewCard();
    };

    const updatePlaybook = async () => {
      if (isFormValid.value) {
        utilService.showToast(isFormValid.value, TYPE.ERROR);
        return;
      }
      try {
        isLoading.value = true;
        playbookData.value.title = previewTrade.value!.title;
        let obj = {
          stocks: stockListing.value,
          title: playbookData.value.title,
          isPrivate: false,
          strategy: playbookData.value.strategy,
        };
        updatePreviewCard();
        var res = await httpService.updatePlaybookV2(
          props.existingPlaybook!._id,
          obj
        );
        if (res.isSuccess) {
          context.emit("formCompleted", res.data);
          dismiss();
        } else {
          utilService.showToast(res.message, TYPE.ERROR);
        }
        isLoading.value = false;
      } catch (e) {
        utilService.showToast("something went wrong", TYPE.ERROR);
        isLoading.value = false;
      }
    };

    const deletePlaybook = async () => {
      try {
        var popupRes = await utilService.showConfirmationDialog(
          "Are you sure you want to delete playbook and all its items?"
        );
        if (popupRes) {
          isDeleteLoading.value = true;
          try {
            var res = await httpService.deletePlaybookV2(
              props.existingPlaybook?._id
            );
            if (res.data.isSuccess) {
              context.emit("onRemove", props.existingPlaybook!._id!);
              popModal();
            } else {
              utilService.showToast(res.data.message, TYPE.ERROR);
              isDeleteLoading.value = false;
            }
          } catch (e) {
            utilService.showToast("Error! Removing Playbook", TYPE.ERROR);
            isDeleteLoading.value = false;
          }
        }
      } catch (e) {}
    };

    const toggleChange = async (type: any) => {
      playbookForm.value.traderType = type;
      if (playbookForm.value.traderType === "long") {
        if (selectedStock.value! && selectedStock.value!.price) {
          playbookForm.value!.entry = selectedStock.value!.price;
          playbookForm.value!.stopLoss = parseFloat(
            (
              selectedStock.value!.price -
              selectedStock.value!.price * 0.1
            ).toFixed(2)
          );
          playbookForm.value!.targetOne = parseFloat(
            (
              selectedStock.value!.price +
              selectedStock.value!.price * 0.05
            ).toFixed(2)
          );
          playbookForm.value!.targetTwo = parseFloat(
            (
              playbookForm.value!.targetOne +
              playbookForm.value!.targetOne * 0.05
            ).toFixed(2)
          );
        }
      }
      if (playbookForm.value.traderType === "short") {
        if (selectedStock.value! && selectedStock.value!.price) {
          playbookForm.value!.entry = selectedStock.value!.price;
          playbookForm.value!.stopLoss = parseFloat(
            (
              selectedStock.value!.price +
              selectedStock.value!.price * 0.1
            ).toFixed(2)
          );
          playbookForm.value!.targetOne = parseFloat(
            (
              selectedStock.value!.price -
              selectedStock.value!.price * 0.05
            ).toFixed(2)
          );
          playbookForm.value!.targetTwo = parseFloat(
            (
              playbookForm.value!.targetOne -
              playbookForm.value!.targetOne * 0.05
            ).toFixed(2)
          );
        }
      }
    };

    const addPlaybook = async () => {
      if (isFormValid.value) {
        utilService.showToast(isFormValid.value, TYPE.ERROR);
        return;
      }
      try {
        isLoading.value = true;
        const dateObject = new Date(previewTrade.value!.timestamp);
        const milliseconds = dateObject.getTime();
        playbookData.value.title = previewTrade.value!.title;
        let obj = {
          timestamp: milliseconds,
          stocks: stockListing.value,
          title: playbookData.value.title,
          isPrivate: false,
          strategy: playbookData.value.strategy,
        };
        updatePreviewCard();
        var res = await httpService.createPlaybookV2(obj);
        if (res.isSuccess) {
          context.emit("formCompleted", res.data);
          dismiss();
        } else {
          utilService.showToast(res.message, TYPE.ERROR);
        }
        isLoading.value = false;
      } catch (e) {
        utilService.showToast("something went wrong", TYPE.ERROR);
        isLoading.value = false;
      }
    };

    const handlePrice = async (type: any, clickWhere: any) => {
      if (type === "stoploss" && clickWhere === "plus") {
        playbookForm.value!.stopLoss += parseFloat(
          (playbookForm.value!.stopLoss * 0.1).toFixed(2)
        );
      } else if (type === "stoploss" && clickWhere === "minus") {
        playbookForm.value!.stopLoss -= parseFloat(
          (playbookForm.value!.stopLoss * 0.1).toFixed(2)
        );
      }
      if (type === "targetOne" && clickWhere === "plus") {
        playbookForm.value!.targetOne += parseFloat(
          (playbookForm.value!.targetOne * 0.05).toFixed(2)
        );
      } else if (type === "targetOne" && clickWhere === "minus") {
        playbookForm.value!.targetOne -= parseFloat(
          (playbookForm.value!.targetOne * 0.05).toFixed(2)
        );
      }
      if (type === "targetTwo" && clickWhere === "plus") {
        playbookForm.value!.targetTwo += parseFloat(
          (playbookForm.value!.targetTwo * 0.1).toFixed(2)
        );
      } else if (type === "targetTwo" && clickWhere === "minus") {
        playbookForm.value!.targetTwo -= parseFloat(
          (playbookForm.value!.targetTwo * 0.1).toFixed(2)
        );
      }
      if (type === "entry" && clickWhere === "plus") {
        playbookForm.value!.entry += parseFloat(
          (playbookForm.value!.entry * 0.05).toFixed(2)
        );
      } else if (type === "entry" && clickWhere === "minus") {
        playbookForm.value!.entry -= parseFloat(
          (playbookForm.value!.entry * 0.1).toFixed(2)
        );
      }
    };

    const changeStatus = () => {
      playbookData.value.strategy = !playbookData.value.strategy;
    };

    const changeStatusAgain = (value:any) => {
      playbookData.value.strategy = value;
    };

    const showNotesModal = async () => {
      var modalRes = await pushModal(AddNotesComponent, {
        data: selectedStock.value!.notes,
      });
      modalRes.on("onSave", (data: any) => {
        selectedStock.value!.notes = data;
      });
    };

    const disabledDate = (time: Date) => {
      const givenDate = new Date(time);
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      const isPastDate = givenDate < todayStart;
      return isPastDate;
    };

    const onEventUpdate = (data: any, index: any) => {
      selectedStock.value = data;
      playbookForm.value.targetOne = data.targetOne;
      playbookForm.value.targetTwo = data.targetTwo;
      playbookForm.value.entry = data.entry;
      playbookForm.value.stopLoss = data.stopLoss;
      playbookForm.value.traderType = data.traderType;
      editMode.value = true;
      editableIndex.value = index;
    };

    const ChangeOption = () => {
      value1.value = true;
    };

    return {
      ChangeOption,
      editMode,
      editableIndex,
      updateStock,
      removeStock,
      onEventUpdate,
      handlePrice,
      disabledDate,
      isFormValid,
      showNotesModal,
      changeStatus,
      selectedStock,
      previewTrade,
      playbookData,
      isDeleteLoading,
      isLoading,
      stockListing,
      addStock,
      toggleChange,
      playbookForm,
      value1,
      updatePreviewCard,
      addPlaybook,
      updatePlaybook,
      handleDragEvent,
      deletePlaybook,
      onStockChange,
      dismiss,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "./_playbook.scss";
</style>
