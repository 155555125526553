import httpService from "@/services/http.service";
import storageService from "@/services/storage.service";
import utilService from "@/services/util.service";
import { ref } from "vue";
import { ConnectionType, RequestStatus } from "./models/request.model";
import { ProfilePrivacy } from "@/views/private/profile-screen/composables/profile.entity";
import User from "@/helpers/models/user.model";
import { DashboardComposables } from "@/views/private/dashboard/dashboard.composables";
import router from "@/router/router";

const isPoperOpen = ref<boolean>(false);
export const ConnectionComposables = () => {
  const isFollowingLoading = ref<boolean>(false);

  const unfollow = async (connectionId?: any) => {
    try {
      document.body.click();
      var confirmRes = await utilService.showConfirmationDialog(
        "Are you sure you want to unfollow?"
      );
      if (confirmRes) {
        isFollowingLoading.value = true;
        const response = await httpService.unFollow({
          connectionId: connectionId,
        });
        if (response.data.isSuccess) {
          utilService.showToast("Unfollowed.");
          isFollowingLoading.value = false;
          return true;
        }
        isFollowingLoading.value = false;
      }
      return false;
    } catch (e) {}
  };

  const startFollowing = async (connectionId?: any) => {
    try {
      isFollowingLoading.value = true;
      const response = await httpService.addConnection({
        connectionId: connectionId,
        requestType: "following",
      });
      if (response.data.isSuccess) {
        utilService.showToast("Started Following");
        isFollowingLoading.value = false;
        return true;
      }
      isFollowingLoading.value = false;
      return false;
    } catch (e) {
      return false;
    }
  };

  const sendFriendRequest = async (
    connectionId?: any,
    isPreApproved: boolean = false
  ) => {
    try {
      isFollowingLoading.value = true;
      const response = await httpService.addConnection({
        connectionId: connectionId,
        requestType: ConnectionType.friendRequest,
        preApprovedPlan: isPreApproved,
      });
      if (response.data.isSuccess) {
        utilService.showToast("Friend Request Sent Successfully");
        isFollowingLoading.value = false;
        return {
          id: response.data.data._id,
          value: true,
        };
      }
      isFollowingLoading.value = false;
    } catch (e) {}
    return undefined;
  };

  const requestReaction = (
    requestId: string,
    requestStatus: RequestStatus,
    profile: any
  ) => {
    return new Promise(async (res, rej) => {
      try {
        isFollowingLoading.value = true;
        const response = await httpService.updateRequestConnection(
          requestId,
          requestStatus
        );
        var reqStatus = "";
        if (requestStatus == RequestStatus.accept) {
          reqStatus = "Accepted";
          profile.isFollowing = true;
        } else if (requestStatus == RequestStatus.reject) {
          reqStatus = "Rejected";
        }
        profile.requestReceiveData = undefined;
        utilService.showToast("Friend request " + reqStatus);
        isFollowingLoading.value = false;
        res(true);
      } catch (e) {
        isFollowingLoading.value = false;
        res(false);
      }
    });
  };

  const onFollow = async (profile: any) => {
    await startFollowing(profile._id);
    profile.isFollowing = true;
    profile.followersCount++;
  };

  const onFriendRequest = async (profile: any) => {
    var res = await sendFriendRequest(profile._id);
    profile.requestSentData = res;
  };

  const onCancelRequest = async (user: any) => {
    try {
      isFollowingLoading.value = true;
      const response = await httpService.cancelRequest({
        connectionId: user._id,
        requestType: ConnectionType.friendRequest,
      });
      isFollowingLoading.value = false;
      if (response.data.isSuccess) {
        utilService.showToast("Request Cancelled");
        user.requestSentData = undefined;
        return true;
      }
      return false;
    } catch (e) {
      isFollowingLoading.value = false;
      return false;
    }
  };

  const onRemoveFriend = async (profile: User) => {
    isFollowingLoading.value = true;
    try {
      const response = await httpService.removeFriend({
        connectionId: profile._id,
      });
      if (response.data.isSuccess) {
        utilService.showToast("Friend Removed.");
        isFollowingLoading.value = false;
        profile.isFriend = false;
        return true;
      }
      isFollowingLoading.value = false;
    } catch (e) {
      isFollowingLoading.value = false;
      return false;
    }
  };

  const blockUser = async (profile: any) => {
    try {
      document.body.click();
      var confirmRes = await utilService.showConfirmationDialog(
        "Are you sure you want to block?"
      );
      if (confirmRes) {
        isFollowingLoading.value = true;
        const response = await httpService.blockUser({
          blockedId: profile._id,
        });
        if (response.data.isSuccess) {
          const { getFilteredUser, usersList, isUsersLoading, page } =
            DashboardComposables();
          page.value = 1;
          usersList.value = [];
          isUsersLoading.value = true;
          await getFilteredUser();
          isUsersLoading.value = false;
          profile.isBlocked = true;
          utilService.showToast("User Blocked");
          isFollowingLoading.value = false;
          router.push({ name: "profile_dashboard" });
          return true;
        }
        isFollowingLoading.value = false;
      }
    } catch (error) {}
  };

  const unBlockUser = async (userId: any) => {
    try {
      document.body.click();
      var confirmRes = await utilService.showConfirmationDialog(
        "Are you sure you want to Unblock?"
      );
      if (confirmRes) {
        isFollowingLoading.value = true;
        const response = await httpService.unBlockUser({
          blockedId: userId,
        });
        if (response.data.isSuccess) {
          utilService.showToast("User UnBlocked");
          isFollowingLoading.value = false;
          return true;
        }
        isFollowingLoading.value = false;
      }
    } catch (error) {}
  };

  return {
    isFollowingLoading,
    onRemoveFriend,
    sendFriendRequest,
    unfollow,
    onCancelRequest,
    requestReaction,
    unBlockUser,
    onFollow,
    onFriendRequest,
    blockUser,
    isPoperOpen,
  };
};
