import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64e2b9a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StockHoverWidget = _resolveComponent("StockHoverWidget")!

  return (_ctx.isPlaceholder)
    ? (_openBlock(), _createElementBlock("figure", {
        key: 0,
        class: _normalizeClass(["stock_logo placeholder", { placeholderWithContent: _ctx.placeholderContent }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToStockDetail && _ctx.goToStockDetail(...args))),
        onTouchstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToStockDetail && _ctx.goToStockDetail(...args))),
        style: _normalizeStyle({
      width: _ctx.size?.width,
      height: _ctx.size?.height,
      flex: `0 0 ${_ctx.size?.width}`,
    })
      }, [
        (_ctx.placeholderContent)
          ? (_openBlock(), _createElementBlock("figcaption", _hoisted_1, _toDisplayString(`${_ctx.placeholderContent}+`), 1))
          : _createCommentVNode("", true)
      ], 38))
    : (_openBlock(), _createElementBlock("figure", {
        key: 1,
        class: _normalizeClass(["stock_logo", _ctx.market]),
        onTouchstart: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goToStockDetail && _ctx.goToStockDetail(...args))),
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.goToStockDetail && _ctx.goToStockDetail(...args))),
        style: _normalizeStyle({
      width: _ctx.size?.width,
      height: _ctx.size?.height,
      flex: `0 0 ${_ctx.size?.width}`,
    })
      }, [
        (_ctx.$filters.validStockLogoUrl(_ctx.imgUrl))
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              ref: "imgTag",
              src: _ctx.imgUrl,
              onError: _cache[2] || (_cache[2] = (event) => _ctx.onImgLoadError(event)),
              style: _normalizeStyle({
          width: _ctx.size?.width,
          height: _ctx.size?.height,
          flex: `0 0 ${_ctx.size?.width}`,
        })
            }, null, 44, _hoisted_2))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              ref: "imgTag",
              onError: _cache[3] || (_cache[3] = (event) => _ctx.onImgLoadError(event)),
              src: _ctx.getStockImage(_ctx.symbol, _ctx.market),
              style: _normalizeStyle({
          width: _ctx.size?.width,
          height: _ctx.size?.height,
          flex: `0 0 ${_ctx.size?.width}`,
        })
            }, null, 44, _hoisted_3)),
        (_ctx.stockData && !_ctx.hideHover)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "hoverEffectDv",
              ref: "hoverWidget",
              style: _normalizeStyle({
        top: _ctx.rectArea.top + 'px',
        left: _ctx.rectArea.left + 'px',
        position: 'fixed',
      })
            }, [
              _createVNode(_component_StockHoverWidget, {
                imgUrl: _ctx.imgUrl,
                symbol: _ctx.symbol,
                sparklineData: _ctx.sparklineData,
                market: _ctx.market,
                stockData: _ctx.stockData
              }, null, 8, ["imgUrl", "symbol", "sparklineData", "market", "stockData"])
            ], 4))
          : _createCommentVNode("", true)
      ], 38))
}