<template>
  <div class="profile_container">
    <div class="bordered" v-if="border"></div>
    <div class="polytar" @click.exact.self="goToProfile($event)">
      <div class="spacing">
        <div>
          <img
            :class="isLocked ? 'opacity' : ''"
            :src="image"
            v-if="image"
            @error="(event) => (event.srcElement.src = getProfileImageAlt())"
          />
          <img
            :class="isLocked ? 'opacity' : ''"
            :src="getProfileImageAlt()"
            v-else
          />
        </div>
        <div class="lock" v-if="isLocked">
          <IconComponent path="" class="" :icon="'lock.svg'" />
          <!-- <img src="../assets/images/lock.png" /> -->
        </div>
      </div>
    </div>
    <div
      class="polybadge"
      @click.stop=""
      :class="{ info_profile: profileUser }"
      v-if="badges"
    >
      <div class="spacing">5</div>
    </div>
    <template v-if="camera">
      <div class="polybadge" v-if="isProfileUploading">
        <div class="spacing">
          <div class="loader"></div>
        </div>
      </div>
      <div class="polybadge camera" v-else @click="$refs.file.click()">
        <div class="spacing c-pointer">
          <IconComponent
            path=""
            class="s-white"
            icon="edit-1-svgrepo-com.svg"
          />
          <input
            ref="file"
            @change="uploadFile"
            class="file-upload d-hidden"
            type="file"
            accept="image/*"
          />
        </div>
      </div>
    </template>
    <template v-if="showQRCode">
      <div class="polybadge camera" @click="openQrShareModal()">
        <div class="spacing c-pointer">
          <IconComponent path="" icon="icon-qrcode.svg" />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import User from "@/helpers/models/user.model";
import utilService from "@/services/util.service";
import QRCodeModal from "@/views/private/QRCode/QRCodeModal.vue";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import IconComponent from "./IconComponent.vue";

export default defineComponent({
  components: {
    IconComponent,
    QRCodeModal,
  },
  props: {
    isProfileUploading: {
      type: Boolean,
    },
    profileUser: {
      type: User,
      default: new User(),
    },
    profileImage: {
      type: String,
      required: true,
    },
    badges: {
      type: Boolean,
      default: true,
    },
    border: {
      type: Boolean,
      default: false,
    },
    camera: {
      type: Boolean,
      default: false,
    },
    toRoute: {
      type: Boolean,
    },
    isDetailPopUp: {
      type: Boolean,
      default: true,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    showQRCode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const image = ref<string>(props.profileImage);

    const uploadFile = (event: any) => {
      context.emit("handleFileUpload", event);
    };

    const goToProfile = (ev: any) => {
      if (ev && !props.toRoute) {
        ev.stopPropagation();
      }
    };

    const getProfileImageAlt = () => {
      var imageUrl = "avatar_men.png";
      if (props.profileUser && props.profileUser.gender) {
        if (props.profileUser && props.profileUser.gender == "Female") {
          imageUrl = "avatar_women.png";
        }
      }
      return utilService.getImageUrl(imageUrl, null);
    };
    const router = useRouter();

    const openQrShareModal = async () => {
      document.body.click();
      utilService.onModalOpen(QRCodeModal);
    };

    return {
      image,
      goToProfile,
      uploadFile,
      getProfileImageAlt,
      openQrShareModal,
    };
  },
});
</script>

<style lang="scss">
$bgGradient: conic-gradient(
  from -30deg at 50% 50%,
  #ffd029,
  #ff1878,
  #a825ff,
  #00fffd
);
$badgeGradient: linear-gradient(
  343deg,
  rgba(0, 255, 253, 0.5) 5%,
  rgba(168, 37, 255, 0.5) 40%,
  rgba(255, 24, 120, 0.5) 70%,
  rgba(255, 208, 41, 0.5) 95%
);

// hexagon
$profile-avatar-clipper: polygon(
  92.32051% 40%,
  93.79385% 43.1596%,
  94.69616% 46.52704%,
  95% 50%,
  94.69616% 53.47296%,
  93.79385% 56.8404%,
  92.32051% 60%,
  79.82051% 81.65064%,
  77.82089% 84.50639%,
  75.35575% 86.97152%,
  72.5% 88.97114%,
  69.3404% 90.44449%,
  65.97296% 91.34679%,
  62.5% 91.65064%,
  37.5% 91.65064%,
  34.02704% 91.34679%,
  30.6596% 90.44449%,
  27.5% 88.97114%,
  24.64425% 86.97152%,
  22.17911% 84.50639%,
  20.17949% 81.65064%,
  7.67949% 60%,
  6.20615% 56.8404%,
  5.30384% 53.47296%,
  5% 50%,
  5.30384% 46.52704%,
  6.20615% 43.1596%,
  7.67949% 40%,
  20.17949% 18.34936%,
  22.17911% 15.49361%,
  24.64425% 13.02848%,
  27.5% 11.02886%,
  30.6596% 9.55551%,
  34.02704% 8.65321%,
  37.5% 8.34936%,
  62.5% 8.34936%,
  65.97296% 8.65321%,
  69.3404% 9.55551%,
  72.5% 11.02886%,
  75.35575% 13.02848%,
  77.82089% 15.49361%,
  79.82051% 18.34936%
);

.profile_container {
  position: relative;

  .polytar {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
    background: $bgGradient;
    box-sizing: border-box;
    clip-path: $profile-avatar-clipper;
    transform: rotate(30deg);

    > .spacing {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 52px;
      height: 52px;
      background: var(--secondary-background-color);
      box-sizing: border-box;
      clip-path: $profile-avatar-clipper;

      .upload-button {
        cursor: pointer;
      }

      > div {
        aspect-ratio: revert;
        object-fit: cover;
        position: absolute;
        top: 4px;
        left: 4px;
        width: 44px;
        height: 44px;
        clip-path: $profile-avatar-clipper;

        img {
          width: 100%;
          height: 100%;
          transform: rotate(-30deg);
          aspect-ratio: revert;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          &.opacity {
            opacity: 0.2;
          }
        }
      }

      .lock {
        height: 15px !important;
        width: 15px !important;
        top: 5px !important;
        left: 7px !important;

        .svg_icon {
          width: 12px;
          transform: rotate(330deg);
          height: 12px;
        }
      }
    }
  }

  .polybadge {
    display: inline-block;
    position: absolute;
    bottom: 8px;
    right: 4px;
    width: 25px;
    height: 25px;
    background: $badgeGradient;
    box-sizing: border-box;
    clip-path: $profile-avatar-clipper;

    > .spacing {
      font-size: var(--font-3xs);
      font-weight: 600;
      color: var(--background-color-alt);
      text-align: center;
      position: absolute;
      top: 3px;
      left: 3px;
      width: 19px;
      height: 19px;
      background: var(--background-color);
      box-sizing: border-box;
      clip-path: $profile-avatar-clipper;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0.9;
      > .svg_icon {
        width: 12px;
        height: auto;
      }
      > .loader {
        width: 8px;
        height: 8px;
      }
    }

    > .cameraIcon {
      width: 16px;
      height: 16px;
    }
  }

  .bordered {
    display: none;
    background: var(--secondary-background-color);
    clip-path: $profile-avatar-clipper;
    position: absolute;
    // display: inline-block;
    transform: rotate(30deg);
    width: 140px;
    height: 140px;
    top: -6%;
    left: -6%;
  }

  &.external {
    cursor: default;

    .polytar {
      background: none;

      + .polybadge {
        display: none;
      }
    }
  }

  &.small {
    > .polytar {
      width: 32px;
      height: 32px;

      .spacing {
        width: 28px;
        height: 28px;
        top: 2px;
        left: 2px;

        div {
          width: 24px;
          height: 24px;
          top: 2px;
          left: 2px;
          bottom: 2px;
          right: 2px;
        }
      }
    }

    .polybadge {
      width: 12px;
      height: 12px;

      .spacing {
        width: 8px;
        height: 8px;
        font-weight: 400;
        font-size: 4px;
      }
    }
  }

  &.medium {
    > .polytar {
      width: 62px;
      height: 62px;

      > .spacing {
        width: 56px;
        height: 56px;
        top: 3px;
        left: 3px;

        > div {
          top: 3px;
          left: 3px;
          width: 50px;
          height: 50px;
        }
      }
    }

    > .polybadge {
      width: 22px;
      height: 22px;

      > .spacing {
        width: 16px;
        height: 16px;
      }
    }
  }

  &.semimedium {
    > .polytar {
      width: 82px;
      height: 82px;

      > .spacing {
        width: 76px;
        height: 76px;
        top: 3px;
        left: 3px;

        > div {
          top: 3px;
          left: 3px;
          width: 70px;
          height: 70px;
        }
      }
    }

    > .polybadge {
      width: 32px;
      height: 32px;

      > .spacing {
        width: 26px;
        height: 26px;
      }
    }
  }

  &.large {
    > .polytar {
      width: 125px;
      height: 125px;

      > .spacing {
        top: 6px;
        left: 6px;
        width: 113px;
        height: 113px;

        > div {
          top: 6px;
          left: 6px;
          width: 101px;
          height: 101px;
        }
      }
    }

    > .polybadge {
      width: 36px;
      height: 36px;
      bottom: 10px;
      right: 10px;

      > .spacing {
        width: 30px;
        height: 30px;
        font-size: var(--font-xs);
      }
    }
  }

  &.semilarge {
    .bordered {
      width: 120px;
      height: 120px;
      top: -5%;
      left: -5%;
    }
    > .polytar {
      width: 110px;
      height: 110px;

      > .spacing {
        top: 5px;
        left: 5px;
        width: 100px;
        height: 100px;

        > div {
          top: 6px;
          left: 6px;
          width: 88px;
          height: 88px;
        }
      }
    }

    > .polybadge {
      width: 32px;
      height: 32px;
      bottom: 18px;

      > .spacing {
        width: 26px;
        height: 26px;
        font-size: var(--font-xs);
      }
    }
  }

  &.xsmall {
    > .polytar {
      width: 58px;
      height: 58px;

      > .spacing {
        width: 50px;
        height: 50px;

        > div {
          width: 42px;
          height: 42px;
        }
      }
    }
  }

  &.mini {
    > .polytar {
      width: 42px;
      height: 42px;

      > .spacing {
        width: 38px;
        height: 38px;
        top: 2px;
        left: 2px;

        > div {
          width: 30px;
          height: 30px;
        }
      }
    }

    .polybadge {
      bottom: 0;
      right: -5px;
    }
  }

  &.extramedium {
    > .polytar {
      width: 70px;
      height: 70px;

      > .spacing {
        width: 64px;
        height: 64px;
        top: 3px;
        left: 3px;

        > div {
          top: 3px;
          left: 3px;
          width: 58px;
          height: 58px;
        }
      }
    }

    > .polybadge {
      width: 22px;
      height: 22px;

      > .spacing {
        width: 16px;
        height: 16px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .profile_container {
    &.extramedium {
      > .polytar {
        width: 55px;
        height: 55px;

        > .spacing {
          width: 50px;
          height: 50px;

          > div {
            width: 43px;
            height: 43px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 280px) {
  .profile_container {
    &.large {
      > .polytar {
        width: 95px;
        height: 95px;
        > .spacing {
          top: 6px;
          left: 6px;
          width: 83px;
          height: 83px;
          > div {
            top: 6px;
            left: 6px;
            width: 71px;
            height: 71px;
          }
        }
      }
      > .polybadge {
        width: 30px;
        height: 30px;
        bottom: 5px;
        right: 5px;
        > .spacing {
          width: 24px;
          height: 24px;
          font-size: var(--font-xs);
        }
      }
    }
  }
}

.file-upload {
  display: none;
}
</style>
