<template>
  <div
    class="playbookCardItem"
    :class="{ editModeInfo: editMode }"
    @click="OpenViewPopup()"
  >
    <IconComponent
      class="iconDv"
      @click="onRemove()"
      @touchstart="onRemove()"
      :id="`minusWining${index}`"
      v-if="showRemove"
      icon="minus.svg"
    />
    <span class="card--tag color-darkgreen">Playbook</span>
    <template v-if="!editMode">
      <div class="firstDv" v-if="trade">
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="trade.title"
          placement="top-start"
          v-if="isOverflowing"
        >
          <h1 ref="title">{{ trade.title }}</h1>
        </el-tooltip>
        <h1 v-else ref="title">{{ trade.title }}</h1>
        <p>{{ dateInfo }}</p>
      </div>
      <div class="firstDv" v-else>
        <h1>-</h1>
        <p>-</p>
      </div>
    </template>
    <template v-else>
      <div class="firstDv" v-if="trade">
        <div class="inputPlaybookDv">
          <el-input
            placeholder="ex Top Gainer"
            class="WinningTradeinputDv playbookFormTitleFeild"
            maxlength="25"
            max="25"
            type="text"
            v-model="trade.title"
          />
          <h5 v-if="!trade.title">Enter Title</h5>
        </div>
        <div class="inputPlaybookDv">
          <el-date-picker
            :disabled-date="disabledDate"
            v-model="trade.timestamp"
            class="ProfitAndLossinputDv newInputSecondDv playbookFormTitleFeild"
            type="date"
            placeholder="Select Date"
          />
          <h5 v-if="!trade.timestamp" class="dateInfoDvSelect">Select Date</h5>
        </div>
      </div>
    </template>
    <div
      class="endDv"
      v-if="stocksInfo && stocksInfo.length"
      ref="stockDvContainer"
    >
      <div class="itemStockDv" v-for="item in stocksInfo" :key="item">
        <StockImageTag
          :disableRouting="true"
          :stockId="item.stockId"
          :imgUrl="item.logoUrl"
          :symbol="item.symbol"
          :market="item.type"
        />
      </div>
      <div
        class="viewMoreDv"
        v-if="maxToShow && trade.stocks && trade.stocks.length > maxToShow"
      >
        <p>+{{ trade.stocks.length - maxToShow }}</p>
      </div>
    </div>
    <div class="endDv" ref="stockDvContainer" v-else>
      <div class="itemStockDv">
        <StockImageTag />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  watch,
  watchEffect,
  nextTick,
} from "vue";
import IconComponent from "@/components/IconComponent.vue";
import { openModal } from "jenesius-vue-modal";
import ViewPlayBookComponent from "./ViewPlayBookComponent.vue";
import utilService from "@/services/util.service";
import storageService from "@/services/storage.service";

export default defineComponent({
  props: {
    trade: Object,
    editMode: Boolean,
    playbookTitle: String,
    playbookDate: String,
    currentUserId: String,
    showRemove: Boolean,
    index: Number,
    fromNotification: Boolean,
    disableRouting: Boolean,
    fromPopup: Boolean,
  },
  components: {
    IconComponent,
  },

  setup(props, context) {
    const stocksInfo = ref<any[]>([]);
    const maxToShow = ref(0);

    const dateInfo = ref("");
    const stockDvContainer = ref<any>();

    const isOverflowing = ref(false);
    const title = ref<HTMLElement | null>(null);

    const checkOverflow = () => {
      nextTick(() => {
        if (title.value) {
          isOverflowing.value =
            title.value.scrollWidth > title.value.clientWidth;
        }
      });
    };

    watch(title, (newValue, oldValue) => {
      if (newValue) {
        checkOverflow();
      }
    });

    const onRemove = () => {
      context.emit("onRemove");
    };

    onMounted(() => {
      user.value = storageService.getUser();
      if (props.trade! && props.trade!.timestamp) {
        dateInfo.value = formatDate(props.trade!.timestamp);
      }
      if (props.trade! && props.trade!.title === "-") {
        props.trade!.title = "";
      }
      if (props.trade! && props.trade!.timestamp === "-") {
        props.trade!.timestamp = "";
      }
      if (props.trade! && props.trade!.stocks && props.trade!.stocks.length) {
        showStocksByWidth(props.trade!.stocks, 3);
      } else {
        stocksInfo.value = [];
      }
    });

    watch(props, () => {
      user.value = storageService.getUser();
      if (props.trade! && props.trade!.title === "-") {
        props.trade!.title = "";
      }
      if (props.trade! && props.trade!.timestamp === "-") {
        props.trade!.timestamp = "";
      }
      if (props.trade! && props.trade!.timestamp) {
        dateInfo.value = formatDate(props.trade!.timestamp);
      }
      if (props.trade! && props.trade!.stocks && props.trade!.stocks.length) {
        showStocksByWidth(props.trade!.stocks, 3);
      } else {
        stocksInfo.value = [];
      }
    });

    const formatDate = (timestamp: any) => {
      const date = new Date(timestamp);
      const today = new Date();

      if (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      ) {
        return "Today";
      } else {
        const options: any = { day: "numeric", month: "long", year: "numeric" };
        return date.toLocaleDateString("en-US", options);
      }
    };

    const user = ref<any>({});

    const OpenViewPopup = () => {
      if (props.fromPopup) {
      } else {
        utilService.onModalOpen(ViewPlayBookComponent, {
          playBookData: props.trade,
          currentUserId: props.currentUserId,
          userID: user.value?._id,
        });
      }
    };

    const showStocksByWidth = (stocks: any, max: number) => {
      maxToShow.value = max;
      stocksInfo.value = stocks.slice(0, maxToShow.value);
    };

    watchEffect(() => {
      if (
        stockDvContainer.value &&
        props.trade!.stocks &&
        props.trade!.stocks.length
      ) {
        var maxToShow = Math.floor(stockDvContainer.value.clientWidth / 55);
        if (props.fromNotification) {
          if (maxToShow === 0) {
            maxToShow = 3;
          }
        }
        showStocksByWidth(props.trade!.stocks, maxToShow);
      }
    });

    const disabledDate = (time: Date) => {
      const givenDate = new Date(time);
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      const isPastDate = givenDate < todayStart;
      return isPastDate;
    };

    return {
      stockDvContainer,
      OpenViewPopup,
      formatDate,
      showStocksByWidth,
      disabledDate,
      dateInfo,
      stocksInfo,
      maxToShow,
      onRemove,
      isOverflowing,
      title,
    };
  },
});
</script>

<style lang="scss" scoped>
.playbookCardItem {
  flex: 0 0 var(--playbook-card-width);
  max-width: var(--playbook-card-width);
  width: var(--playbook-card-width);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--card-inner-color);
  gap: 10px;
  border-radius: 10px;
  padding: 25px 15px 15px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5019607843);
  &.selectedOutline {
    box-shadow: 0 0 0px 2px var(--color-warning),
      0px 0px 10px rgba(0, 0, 0, 0.5019607843);
  }
  .firstDv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 0 0 115px;
    overflow: hidden;
    margin-top: 12px;
    gap: 10px;
    .inputPlaybookDv {
      position: relative;
      width: 100%;
      h5 {
        line-height: 1;
        letter-spacing: 0px;
        font-size: var(--font-xs);
        margin: 0px;
        padding: 0px;
        position: absolute;
        left: 0;
        top: 7px;
        color: var(--text-color);
        opacity: 0.7;
        font-weight: 400;
        z-index: 0;
        &.dateInfoDvSelect {
          left: 20px;
        }
      }
    }
    h1 {
      font-size: var(--font-2sm);
      line-height: 1;
      letter-spacing: 0px;
      margin: 0px;
      padding: 0px;
      font-weight: 600;
      color: var(--card-inner-color-alt);
      text-align: start;
      width: 100%;
      @include truncate();
    }
    p {
      font-size: var(--font-xs);
      line-height: 1;
      letter-spacing: 0px;
      margin: 0px;
      padding: 0px;
      font-weight: 500;
      color: var(--grey-color);
      text-align: start;
      width: 100%;
      @include truncate();
    }
  }
  .endDv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 5px;
    // flex-grow: 1;
    flex: 0 0 170px;
    .viewMoreDv {
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 25px;
      height: 25px;
      p {
        font-size: var(--font-2sm);
        line-height: 1;
        letter-spacing: 0px;
        margin: 0px;
        text-align: end;
        padding: 0px;
        font-weight: 500;
        color: var(--sub-heading-color);
      }
    }
  }
  .iconDv {
    width: 18px;
    height: auto;
    right: -6px;
    position: absolute;
    top: -6px;
  }
  &.editModeInfo {
    max-height: 87px;
    max-width: var(--playbook-card-width);
    .firstDv {
      margin-top: 8px;
      gap: 0px;
    }
  }
}
@media screen and (max-width: 767px) {
  .playbookCardItem {
    .firstDv {
      flex: 0 0 auto;
    }
  }
}
</style>
