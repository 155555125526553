export default class WatchList {
  _id: string | undefined;
  symbol: string | undefined;
  name: string | undefined;
  price: number | undefined;
  change: number | undefined;
  changePercent: number | undefined;
  image: string | undefined;
  market: string | undefined;
  country: string | undefined;
  description?: string | undefined;
  isDetailWatchlist: boolean = false;
  historicalData: string | undefined;
  watchlistType: string = "";
  exchange: string | undefined;
  predictedPrice: number | undefined;
  predictionDate: string = "";
  stock_id: string | undefined;

  public static getGeneralObjectForStockData(obj: any) {
    return {
      _id: obj.stock_id,
      symbol: obj.symbol,
      companyName: obj.name,
      last: obj.price,
      change: obj.change,
      changePercent: obj.changePercent,
    };
  }
}

